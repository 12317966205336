import React from 'react'
import classNames from 'classnames'

import WysiwygContent from '../WysiwygContent'

const questionClasses = `
  font-sans
  font-bold
  text-lg
  mb-2
`

const FaqItem = ({ question, answer, id }) => (
  <div className="text-dark-grey-2">
    <div id={ id } className={ questionClasses }>{ question }</div>
    { answer &&
        <WysiwygContent content={ answer } />
    }
  </div>
)

export default FaqItem
