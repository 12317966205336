import React from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'
import Faq from '../../../components/Templates/Faq'
import NoTranslation from '../../../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

const getQAs = page => {
  return page.data.body.map(qa => {
    return {
      question: qa.primary.question.text,
      answer: qa.primary.answer.html,
      id: qa.primary.question_id.text
    }
  })
}

const FaqPage = ({ data, pageContext }) => {
  const { prismicFaq } = data
  if (!prismicFaq) return (<NoTranslation></NoTranslation>)

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(prismicFaq),
    mantelData: extract.mantel(prismicFaq, {
      parent: {
        path: localizePath(pageContext.locale, '/what-we-do'),
        text: 'What We Do'
      }
    }),
    faqItems: getQAs(prismicFaq)
  }
  return (
    <Faq { ...hydratedData } />
  )
}

export default withPreview(FaqPage)

export const pageQuery = graphql`
  query FaqByLocale( $locale: String! ) {
    prismicFaq( lang: { eq: $locale } ) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        body {
          ... on PrismicFaqBodyQuestionAndAnswer {
            primary {
              question {
                text
              }
              answer {
                html
              }
              question_id {
                text
              }
            }
          }
        }
      }
    }
  }
`
