import React from 'react'
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import FaqItem from '../../General/FaqItem'

const Faq = ({ seo, mantelData, faqItems }) => (
  <PageWrap bg={3} seo={seo}>
    <Mantel {...mantelData} />
    <div className="max-w-xl mx-auto py-8">
      {faqItems.map((faqItem, i) => (
        <div
          key={`faq-item-${i}`}
          className={faqItems.length === i + 1 ? '' : 'mb-5'}
        >
          <FaqItem
            number={i + 1}
            question={faqItem.question}
            answer={faqItem.answer}
            id={faqItem.id}
          />
        </div>
      ))}
    </div>
  </PageWrap>
)

export default Faq
